@import "common/styles/reset";
@import "common/styles/mixins";
@import "common/styles/vendor/accordion";

html {
  font-size: 16px;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  width: 100%;
  margin: 0;
  font-family: "Gilroy", "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container {
  width: 100%;
  max-width: 1440px;
  padding: 0 1rem;
  margin: 0 auto;

  @include ctx_mobile {
    @include ctx-landscape {
      padding: 0 3.75rem;
    }
  }
}

.root__device-type_mobile {
  .container {
    @include ctx-landscape {
      padding: 0 3.75rem;
    }
  }
}

.swiper.swiper-initialized.swiper-horizontal.swiper-pointer-events {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;

  .swiper-slide {
    cursor: pointer;
  }
}

.etAnimatedLeft {
  &First {
    background: #032436;
  }

  &Second {
    background: #033550;
  }

  &Third {
    background: #044C75;
  }

  &Fourth {
    background: #046195;
  }

  &Fifth {
    background: #0478BA;
  }
}

::selection {
  color: #0478B9;
  background: yellow;
}

// Tabs from ExpertiseTabs component
.react-tabs {
  display: flex;
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  width: 170px;
  margin: 0;
  padding: 0;
}

.react-tabs__tab {
  list-style: none;
  cursor: pointer;
  bottom: 0;
}

.react-tabs__tab--selected {
  border-radius: none !important;
  border: none;
  padding: none;

  p {
    border-bottom: 3px solid #00C3EE !important;
  }
}

.react-tabs__tab-panel {
  display: none !important;
}

div[role="tabpanel"] {
  display: none;

  &.react-tabs__tab-panel--selected {
    display: flex !important;
  }
}

.react-tabs__tab-panel--selected {
  display: flex;
}

.react-tabs__tab {
  transition: all 300ms ease;
}

.react-tabs__tab--selected {
  border: none !important;
  color: #000017 !important;
}

.panel-content {
  justify-content: space-between;
}


