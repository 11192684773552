@import 'src/common/styles/helpers';

.subheader {
  height: 700px;
  margin-bottom: 120px !important;

  @include ctx_mobile {
    height: unset;
    margin-bottom: 3.75rem !important;
  }

  &Wrapper {
    position: relative;
    height: 100%;
    background: url("./images/subheader-pattern-bg.png") no-repeat 100% 100%;
    background-size: cover;
    display: flex;
    align-items: self-start;
    flex-direction: column;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 4.375rem;
    padding-left: 7%;
    overflow: visible;

    &::before {
      content: '';
      position: absolute;
      display: flex;
      background: #f5f5fa;
      left: -60px;
      top: 0;
      z-index: -1;
      height: 100%;
      width: 50%;
    }

    @include ctx_mobile {
      margin-bottom: 0;
      padding: 1.25rem 7% 1.5625rem 7%;
      background: url("./images/subheader-pattern-bg.png") no-repeat 62% 70%;
      background-size: cover;

      @include ctx-landscape {
        background-size: contain;
      }
    }
  }

  &Title {
    @extend .h1;
    @extend .fw-bolder;

    max-width: 735px;
    z-index: 1;
    color: #032436;
    margin-bottom: 23px;

    @include ctx_mobile {
      font-size: 2.5625rem;
      line-height: 120%;
    }
  }

  &Description {
    @extend .fw-bold;

    font-size: 28px;
    margin-bottom: 64px;
    z-index: 1;

    @include ctx_mobile {
      line-height: 120%;
      margin-bottom: 2.5rem;
    }
  }

  &ContactUs {
    border: none;
    background-color: #032436;
    padding-top: 16px;
    padding-bottom: 16px;
    cursor: pointer;
    position: relative;
    border-radius: 100px;
    font-weight: 700;
    font-size: 16px;
    width: 250px;
    color: #ffffff;
    transition: background-color 300ms ease;

    @include ctx_mobile {
      @include ctx-portrait {
        width: 100%;
      }
    }

    &:hover {
      background-color: #0478ba;
    }
  }
}
