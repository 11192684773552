@import '../../common/styles/helpers';
@import '../../common/styles/variables';
@import '../../common/styles/mixins';
@import '../../common/styles/common';

$title-indent-desktop: 3rem;
$title-indent-mobile: 2.375rem;

.ops {
  @extend .container-inner;

  position: relative;
  margin-bottom: 188px;

  @include ctx_mobile {
    margin-top: 2.5rem;
    margin-bottom: 5.5rem;
  }

  &Title {
    @extend .h1;
    @extend .fw-bolder;

    color: #032436;
    margin-bottom: 72px;

    @include ctx_mobile {
      margin-bottom: 2.5rem;
    }
  }

  &Card {
    display: flex;
    overflow: hidden;
    padding: 24px;
    border-radius: 40px;
    box-shadow: 0 4px 23px rgba(3, 36, 54, 0.1), 0 4px 3px rgba(3, 36, 54, 0.05);

    @include ctx_mobile {
      flex-direction: column;
    }

    &Image {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-right: 40px;

      width: 280px;
      height: 280px;
      border-radius: 40px;
      overflow: hidden;
      background: #F5F5F5;

      @include ctx_mobile {
        width: 100%;
        max-width: 400px;
        margin-right: 0;
        align-self: center;
        justify-content: center;
        margin-bottom: 48px;
      }
    }

    &Ctnt {

      &Title {
        color: #032436;
        font-size: 28px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        margin-bottom: 16px;
      }

      ul {
        color: #4D4D5C;
        font-size: 16px;
        font-weight: 500;
        padding-left: 18px;
      }

      &Ctrlr {
          border: none;
          background-color: #032436;
          padding-top: 16px;
          padding-bottom: 16px;
          cursor: pointer;
          position: relative;
          border-radius: 100px;
          font-weight: 700;
          font-size: 16px;
          width: 250px;
          color: #ffffff;
          transition: background-color 300ms ease;
          margin-top: 34px;

          @include ctx_mobile {
            @include ctx-portrait {
              width: 100%;
            }
          }

          &:hover {
            background-color: #0478ba;
          }
        }
    }

    &Grain {
      margin-left: auto;
      margin-top: auto;
      position: relative;
      right: -24px;
    }
  }
}
