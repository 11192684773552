@import '../../common/styles/helpers';
@import '../../common/styles/variables';
@import '../../common/styles/mixins';
@import '../../common/styles/common';

.about {
  @extend .container-inner;

  margin-bottom: 190px;

  @include ctx_mobile {
    margin-bottom: 2.5rem;
  }

  &Title {
    @extend .h1;
    @extend .fw-bolder;

    color: #032436;
    margin-bottom: 48px;

    @include ctx_mobile {
      margin-bottom: 2.5rem;
    }
  }

  &Content {
    position: relative;
    display: flex;
    justify-content: space-between;

    &Item {
      max-width: 21.5rem;

      &Title {
        font-weight: 600;
        font-size: 28px;
        color: $tangaroa;
        margin-bottom: 24px;
      }

      &Description {
        font-weight: 500;
        font-size: 16px;
        color: $trout;
      }

      @include ctx_mobile {
        max-width: unset;
        margin-top: 2.5rem;

        &:first-child {
          margin-top: 0;
        }
      }
    }

    &FinanceReport {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(3.125rem);
    }

    &FinanceReportLink {
      color: $tangaroa;

      &:hover {
        //text-decoration: none;
        font-weight: 700;
      }
    }

    @include ctx_mobile {
      flex-direction: column;
    }
  }
}
