@import '../../common/styles/helpers';
@import '../../common/styles/variables';
@import '../../common/styles/common';

.ftr {
  @extend .container-inner;

  padding: 62px 14% 46px 62px !important;
  max-width: 1168px;
  background: $whisper;
  border-top-left-radius: 80px;
  display: flex;
  position: relative;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;

  @include ctx_mobile {
    border-top-left-radius: 0;
    padding: 2.5rem 1rem 2.5rem 1rem !important;

    img[alt="Ukrainian Grain"] {
      margin-bottom: 2rem;
    }

    @include ctx-landscape {
      flex-direction: row;
      justify-content: space-between;
    }

    @include ctx-portrait {
      flex-direction: column;
    }
  }

  &::before {
    content: url("./images/pattern-left.png");
    position: absolute;
    z-index: 0;
    left: 0;
    top: 0;
    height: 100%;

    @include ctx_mobile {
      display: none;
    }
  }

  &::after {
    content: url("./images/pattern-right.png");
    position: absolute;
    z-index: 0;
    right: 0;
    top: 0;
    height: 100%;

    @include ctx_mobile {
      content: url("./images/mob-pattern-right.png");
      top: 2px;
    }
  }

  &Title {
    @extend .h1;
    @extend .fw-bolder;

    color: #032436;
  }

  &Description {
    font-weight: 500;
    font-size: 1rem;
    color: $tangaroa;
    margin-bottom: 1rem;

    @include ctx_mobile {
      margin-bottom: 25px;
    }
  }

  &Col {
    display: flex;
    flex-direction: column;
    z-index: 1;

    @include ctx_mobile {
      @include ctx-landscape {
        width: 50%;

        &:nth-child(2) {
          align-items: flex-end;
        }
      }

      @include ctx-portrait {
        width: 100%;
      }
    }
  }

  &Phone {
    display: flex;
    margin-bottom: 1rem;

    a {
      color: $tangaroa;
    }
  }

  &Link {
    text-decoration: none;
    font-weight: 500;
    color: $tangaroa;
  }

  &Email {
    display: flex;
    margin-bottom: 1rem;

    a {
      color: $tangaroa;
      text-decoration: none;
      cursor: pointer;
    }
  }

  &Address {
    display: flex;
    color: $tangaroa;
  }

  &Icon {
    margin-right: 8px;
  }
}
