@import '../../common/styles/helpers';
@import '../../common/styles/variables';
@import '../../common/styles/mixins';
@import '../../common/styles/common';

$title-indent-desktop: 3rem;
$title-indent-mobile: 2.375rem;

.op {
  @extend .container-inner;

  &Title {
    @extend .h1;
    @extend .fw-bolder;

    color: #032436;
    margin-bottom: 72px;

    @include ctx_mobile {
      margin-bottom: 2.5rem;
    }
  }

  &Card {
    position: relative;
    border-radius: 80px;
    display: flex;
    margin-bottom: 72px;
    overflow: hidden;

    @include ctx_mobile {
      border-radius: 40px;
      margin-bottom: 2rem;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    &Wide {
      position: relative;
      margin-bottom: 72px;
      overflow: hidden;
      border-radius: 80px;

      @include ctx_mobile {
        border-radius: 40px;
        margin-bottom: 2rem;
      }

      &Title {
        position: absolute;
        top: $title-indent-desktop;
        left: $title-indent-desktop;
        z-index: 1;

        @include ctx_mobile {
          top: $title-indent-mobile;
          left: $title-indent-mobile;
        }
      }

      &Count {
        position: absolute;
        color: $tangaroa;
        top: $title-indent-desktop;
        right: $title-indent-desktop;
        z-index: 1;

        @include ctx_mobile {
          top: $title-indent-mobile;
          right: $title-indent-mobile;
        }
      }

      img {
        transition: transition 200ms ease-in-out;
      }

      .opCardBg {
        img {
          @include ctx_mobile {
            @include ctx-landscape {
              transform: translateY(0);
            }
          }
        }
      }

      &:hover {
        img {
          transform: scale(1.1);
        }
      }
    }

    &Col {
      display: flex;
      justify-content: space-between;

      @include ctx_mobile {
        flex-direction: column;
      }
    }

    &Wrapper {
      display: flex;
      flex-direction: column;
    }

    &Title {
      position: absolute;
      top: $title-indent-desktop;
      left: $title-indent-desktop;
      color: #032436;
      font-weight: 600;
      font-size: 28px;
      z-index: 1;

      @include ctx_mobile {
        font-size: 24px;
        top: $title-indent-mobile;
        left: $title-indent-mobile;
      }
    }

    &Count {
      position: absolute;
      top: $title-indent-desktop;
      right: $title-indent-desktop;
      color: $tangaroa;
      font-weight: 600;
      font-size: 28px;
      z-index: 1;

      @include ctx_mobile {
        font-size: 24px;
        top: $title-indent-mobile;
        right: $title-indent-mobile;
      }
    }

    &Description {
      position: absolute;
      top: 130px;
      width: 80%;
      left: 48px;
      font-weight: 500;
      font-size: 16px;
      color: #032436;
      z-index: 1;
    }

    &Bg {
      width: 100%;
      height: 100%;

      img {
        display: flex;
        transition: all 1000ms ease-in-out;

        @include ctx_mobile {
          width: 100%;
        }
      }

      @include ctx_mobile {
        @include ctx-landscape {
          height: 16.875rem;
          overflow: hidden;

          img {
            transform: translateY(-15%);
          }
        }
      }
    }
  }
}
