@import '../../common/styles/helpers';
@import '../../common/styles/variables';
@import '../../common/styles/common';

.et {
  @extend .container-inner;

  margin-bottom: 190px;

  @include ctx_mobile {
    margin-bottom: 2.5rem;
  }

  &Tab {
    margin-left: 0;
    padding-left: 0;
    padding: 16px 0;
    width: 100%;
    height: auto;

    &Wrapper {
      padding-left: 0;
      padding: 36px;
      box-shadow: 0 4px 23px rgba(3, 36, 54, 0.1), 0 4px 3px rgba(3, 36, 54, 0.05);
      border-radius: 40px;
      display: flex;
    }

    &List {
      padding-left: 0;
      padding-right: 36px;
      min-width: 349px;
      border-right: 2px solid #EBECF0;

      li {
        list-style: none;
        outline: transparent auto 0;
        font-weight: 600;
        color: #8B8C98;
        cursor: pointer;

        p {
          display: inline-flex;
          transition: all 300ms ease;
          border-bottom: 3px solid transparent;
        }
      }
    }

    &Panel {
      display: flex;
      flex-wrap: wrap;
      padding: 1rem 0 2rem 2rem;

      &Col {
        display: flex;
        flex-direction: column;
        width: 50%;

        @include ctx_desktop {
          min-width: 22.3438rem;
        }
      }

      &Item {
        display: flex;
        flex-direction: column;
        padding-right: 2rem;
        margin-bottom: 2rem;

        &Title {
          color: #032436;
          font-weight: 700;
          font-size: 20px;
          margin-bottom: 0.5rem;
        }

        &Content {
          color: #4D4D5C;
          font-weight: 500;
          font-size: 1rem;
        }
      }
    }
  }
}
