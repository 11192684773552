@import "src/common/styles/vendor/accordion";

.ea {
  margin-bottom: 96px;
  background: #ffffff;

  &Root {
    &Accordion {
      border: 1px solid #D0D0D9;
      border-radius: 10px;

      &Icon {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        transition: all 300ms ease;
      }

      &Item {

        &Title {
          font-weight: 700;
          font-size: 16px;
          color: #032436;
          margin-bottom: 0.5rem;
        }

        &Description {
          font-weight: 500;
          font-size: 16px;
          color: #4D4D5C;
          margin-bottom: 1.5rem;
        }
      }
    }
  }
}
