@import '../../common/styles/helpers';
@import '../../common/styles/variables';
@import '../../common/styles/common';

.artifacts {
  @extend .container-inner;

  position: relative;
  margin-bottom: 188px;

  @include ctx_desktop {
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 40rem;
      height: 42rem;
      background: url("./images/x-pattern.png") no-repeat center center;
      background-size: 100%;
      content: "";
      transform: translate(-53%, -4%);
    }
  }

  @include ctx_mobile {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  &Title {
    @extend .h1;
    @extend .fw-bolder;

    position: relative;
    z-index: 1;
    color: #032436;
    margin-bottom: 48px;

    @include ctx_mobile {
      margin-bottom: 2.5rem;
    }
  }

  &Content {
    display: flex;
    flex-direction: column;
    z-index: 1;

    &Row {
      display: flex;

      @include ctx_mobile {
        align-items: center;

        @include ctx-landscape {
          flex-direction: row;
          justify-content: space-between;
        }

        @include ctx-portrait {
          flex-direction: column;
        }
      }
    }

    &Item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      box-shadow: 0 4px 23px rgba(3, 36, 54, 0.1), 0 4px 3px rgba(3, 36, 54, 0.05);
      border-radius: 40px;
      padding: 32px 0;
      margin-bottom: 40px;
      margin-right: 40px;
      z-index: 1;

      &Description {
        font-size: 1rem;
        font-weight: 700;
        color: #032436;
      }

      &Icon {
        margin-bottom: 24px;
      }

      &_project_documentation {
        width: 26rem;
      }

      &_codebase {
        width: 12.625rem;
      }

      &_project_deployment_scripts {
        width: 29.875rem;
      }

      &_operational_documentation {
        width: 31.625rem;
      }

      &_load_testing_results {
        width: 23.6875rem;
      }

      &_any_other {
        width: 13.1875rem;
      }

      &_project_documentation,
      &_codebase,
      &_project_deployment_scripts,
      &_operational_documentation,
      &_load_testing_results,
      &_any_other {
        @include ctx_mobile {
          @include ctx-landscape {
            width: 31%;
          }

          @include ctx-portrait {
            width: 100%;
          }
        }
      }

      @include ctx_mobile {
        margin-right: 0;
      }
    }
  }
}
