@import '../../common/styles/helpers';
@import '../../common/styles/variables';
@import '../../common/styles/mixins';
@import '../../common/styles/common';

$item-border-radius: 2rem;

.et {
  @extend .container-inner;

  margin-bottom: 4.5rem;

  &Title {
    @extend .h1;
    @extend .fw-bolder;

    color: #032436;
    margin-bottom: 4.5rem;

    @include ctx_mobile {
      margin-bottom: 2.5rem;
    }
  }

  &Animated {
    &Wrapper {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;

      @include ctx_mobile {
        flex-direction: column;
      }
    }

    &Left {
      display: flex;
      position: relative;
      min-width: 38%;
      border-radius: $item-border-radius $item-border-radius 5rem $item-border-radius;
      box-shadow: 0 4px 23px rgba(3, 36, 54, 0.1), 0 4px 3px rgba(3, 36, 54, 0.05);
      color: $white;
      font-weight: 700;
      padding: 2.375rem;
      z-index: 1;

      @include ctx_mobile {
        width: 100%;
        padding: 1.5625rem;
      }
    }

    &Right {
      position: relative;
      display: flex;
      align-items: center;
      padding: 0 2.375rem;
      flex-grow: 1;
      min-height: 6.25rem;
      border-radius: 0 1.25rem 1.25rem 0;
      background: #F4F5FA;
      color: #4D4D5C;
      font-weight: 500;
      z-index: 0;

      &::before {
        position: absolute;
        top: 0;
        bottom: 0;
        left: -10%;
        z-index: -1;
        width: 10%;
        height: 100%;
        background: #F4F5FA;
        content: '';

        @include ctx_mobile {
          left: 0;
          width: 100%;
          height: 50%;
          transform: translateY(-70%);
        }
      }

      @include ctx_mobile {
        width: 100%;
        border-radius: 0 0 5rem $item-border-radius;
        padding: 2.375rem 1.5625rem;
      }
    }
  }
}
