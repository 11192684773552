@import "../common/styles/mixins";

.orbitek {
  &__app {
    display: flex;
    flex-direction: column;
  }

  &__cookie_content {
    display: flex;
    align-items: center;
  }

  &__cookie_graphic {
    width: 3.125rem;
    height: 3.125rem;
    display: inline-flex;
    animation: cookies-rotation 5000ms linear infinite;

    @include ctx_mobile {
      min-width: 4rem;
      width: 4rem;
      height: 4rem;
    }
  }

  &__cookie_text {
    display: inline-flex;
    margin-left: 1rem;
  }
}

@keyframes cookies-rotation {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(360deg);
  }
}
