@import "./utils";
// This function gets selector, parse it to elements and then adding elements to selectors,
// which have needed suffix with separation of without it.
@function group-context-classes($selector, $classSuffix, $className, $separated: false) {
  $modified-list: ();

  @each $selectorLine in $selector {
    $inner-list: ();

    @each $selectorElement in $selectorLine {
      @if (str-index($selectorElement, $classSuffix)) {
        @if ($separated) {
          $inner-list: append($inner-list, global-classname(#{$selectorElement}));
          $inner-list: append($inner-list, global-classname(#{$className}));
        } @else {
          $inner-list: append($inner-list, #{global-classname($selectorElement)}#{global-classname($className)});
        }
      } @else {
        $inner-list: append($inner-list, $selectorElement);
      }
    }
    $modified-list: append($modified-list, $inner-list, comma);
  }

  @return $modified-list;
}

$root-suffix: ".root__";

// Currently we have two levels of context in our application
// Root context('root__') and Game context ('game-table_')
// We want all our styles to compile in needed order
// root classes -> game-table classes -> specific classes
// this mixin provide behavior, which we need and adding context class in needed position
@mixin context-class($className, $contextSuffix) {
  @if (str-index(#{&}, $contextSuffix)) {
    @at-root #{group-context-classes(&, $contextSuffix, $className)} {
      @content;
    }
  } @else if (str-index(#{&}, $root-suffix)) {
    @at-root #{group-context-classes(&, $root-suffix, $className, true)} {
      @content;
    }
  } @else if (& == null) {
    #{global-classname($className)} {
      @content;
    }
  } @else {
    @at-root #{global-classname($className)} & {
      @content;
    }
  }
}

@mixin root-class($rootClass) {
  @include context-class($rootClass, $root-suffix) {
    @content;
  }
}

@mixin root-classes($rootClasses...) {
  @each $rootClass in $rootClasses {
    @include root-class($rootClass) {
      @content;
    }
  }
}
