@import "root-classes";

@mixin ctx-landscape {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin ctx-portrait {
  @media (orientation: portrait) {
    @content;
  }
}

@mixin ctx_desktop {
  @include root-classes(".root__device-type_desktop") {
    @content;
  }
}

@mixin ctx_tablet {
  @include root-classes(".root__device-type_tablet") {
    @content;
  }
}

@mixin ctx_desktop-and-tablet {
  @include root-classes(".root__device-type_desktop") {
    @content;
  }

  @include root-classes(".root__device-type_tablet") {
    @content;
  }
}

@mixin ctx_mobile {
  @include root-classes(".root__device-type_mobile") {
    @content;
  }
}

@mixin ctx_tablet-and-mobile {
  @include root-classes(".root__device-type_tablet", ".root__device-type_mobile") {
    @content;
  }
}
