@import "~src/common/styles/mixins";

.logo {
  display: flex;

  &__image {
    min-width: 10rem;
    height: 2.9375rem;
  }

  &__description {
    position: relative;
    min-width: 9.125rem;
    display: flex;
    align-items: center;
    margin-left: 0.6875rem;
    padding-left: 0.9375rem;

    &::before {
      position: absolute;
      left: 0;
      top: 50%;
      width: 0.25rem;
      height: 2.25rem;
      background-color: #bee5f6;
      content: "";
      transform: translateY(-50%);
    }

    @include ctx_mobile {
      display: none;
    }
  }

  &__description_image {
    width: 8.1875rem;
    height: auto;
  }
}
