@keyframes top {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(0) translateY(0.375rem);
  }
  100% {
    transform: rotate(45deg) translateY(0.375rem);
  }
}

@keyframes top-back {
  0% {
    transform: rotate(45deg) translateY(0.375rem);
  }
  50% {
    transform: rotate(0deg) translateY(0.375rem);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}

@keyframes bottom {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(0) translateY(-0.375rem);
  }
  100% {
    transform: rotate(135deg) translateY(-0.375rem);
  }
}

@keyframes bottom-back {
  0% {
    transform: rotate(135deg) translateY(-0.375rem);
  }
  50% {
    transform: rotate(0) translateY(-0.375rem);
  }
  100% {
    transform: rotate(0);
  }
}

@keyframes scaled {
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes scaled-back {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes menu-item-appear {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes menu-item-underline-appear {
  0% {
    opacity: 0;
    transform: translate(-50%, 100%) skewY(0);
  }

  80% {
    opacity: 0.5;
    transform: translate(-50%, 50%) skewY(10deg);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%) skewY(0);
  }
}

@keyframes menu-phone-appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
