@import '../../common/styles/helpers';
@import '../../common/styles/variables';
@import '../../common/styles/common';

.greeting {
  @extend .container;

  margin-bottom: 70px !important;

  @include ctx_desktop {
    padding-left: 7%;
  }

  &Flag {
    display: flex;
    margin-left: 24px;
  }

  &Title {
    @extend .h1;
    @extend .fw-bolder;

    display: flex;
    color: $tangaroa;

    @include ctx_mobile {
      display: inherit;
      line-height: 120%;

      img {
        display: inline-flex;
      }
    }
  }

  &Content {
    display: flex;
    justify-content: space-between;
    margin-top: 70px;

    @include ctx_mobile {
      flex-direction: column;
      margin-top: 1rem;
    }

    &Text {
      max-width: 350px;
      color: #4D4D5C;
      font-size: 1rem;
      font-weight: 500;

      @include ctx_mobile {
        max-width: unset;
        margin-bottom: 20px;
      }
    }

    &_image {
      width: 100%;
    }
  }

  &Content_image_wrapper {
    @include ctx_desktop {
      margin-left: 3.125rem;
    }
  }
}
