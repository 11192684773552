@import '../../common/styles/helpers';
@import '../../common/styles/variables';
@import '../../common/styles/common';

.hww {
  @extend .container-inner;

  display: flex;
  position: relative;
  margin-bottom: 190px;
  justify-content: space-between;

  @include ctx_mobile {
    flex-direction: column;
    margin-bottom: 2.5rem;
  }

  &::before {
    position: absolute;
    top: 40%;
    left: 20%;
    width: 49.25rem;
    height: 46.3125rem;
    z-index: -1;
    background: url("./images/x-pattern.png") no-repeat center center;
    background-size: 100%;
    content: "";
    transform: translate(-50%, -50%);

    @include ctx_mobile {
      display: none;
    }
  }

  &Title {
    @extend .h1;
    @extend .fw-bolder;

    z-index: 1;
    color: #032436;

    &Wrapper {
      position: relative;
      display: flex;
    }
  }

  &Content {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    &Item {
      position: relative;
      max-width: 362px;

      &:first-child {
        width: 34%;

        @include ctx_mobile {
          width: 100%;
          margin-bottom: 40px;
        }
      }

      &:not(:nth-child(1)) {
        align-self: flex-start;

        @include ctx_mobile {
          align-self: center;
        }
      }

      &Inner {
        position: relative;
        z-index: 3;
        background-color: #ffffff;
        box-shadow: 0 4px 23px rgba(3, 36, 54, 0.1), 0 4px 3px rgba(3, 36, 54, 0.05);
        border-radius: 40px;
        display: inline-block;
        margin-bottom: 36px;
        padding: 34px;

        img {
          margin-bottom: 24px;
        }

        @include ctx_mobile {
          @include ctx-landscape {
            min-width: 47%;
            width: 47%;
          }
        }
      }

      &Title {
        font-weight: 600;
        font-size: 28px;
        color: $tangaroa;
        margin-bottom: 24px;
      }

      &Description {
        font-weight: 500;
        font-size: 16px;
        color: $trout;
      }

      @include ctx_mobile {
        @include ctx-landscape {
          max-width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
