@import './variables';
@import './mixins';

.h {
  &1 {
    font-size: 4.125rem;

    @include ctx_mobile {
      font-size: 2.8125rem;
    }
  }

  &2 {
    font-size: 3rem;
  }

  &3 {
    font-size: 2rem;
  }

}

.fw {
  &-normal {
    font-weight: normal;
  }

  &-bold {
    font-weight: bold;
  }

  &-bolder {
    font-weight: 800;
  }
}

.color {
  &Blue {
    color: $lochmara;
  }
}
