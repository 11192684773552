.accordion {
  border: 1px solid #D0D0D9;
  border-radius: 10px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid #D0D0D9;
}

.accordion__button {
  color: #93939E;
  font-weight: 500;
  font-size: 16px;
  position: relative;
  padding: 18px 18px 18px 45px;
  width: 100%;
  text-align: left;
  border-top: 1px solid #D0D0D9;
  transition: all 300ms ease;
}

.accordion__button[aria-expanded="true"] {
  font-weight: 700;
  color: #032436;
}

.accordion__button:first-child {
  border-top: 1px solid transparent;
}

.accordion__button:before {
  display: flex;
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
  transition: all 300ms ease;
  content: url("../../../sections/expertise-accordion/icons/expand-collapse.svg");
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  content: url("../../../sections/expertise-accordion/icons/expand-collapse-active.svg");
  left: 13px;
  transform: translateY(-69%) rotate(180deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 0 47px 20px 47px;
  animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
