@import "./mixins";

.container {
  width: 100%;
  max-width: 1440px;
  padding: 0 1rem;
  margin: 0 auto;

  @include ctx_mobile {
    @include ctx-landscape {
      padding: 0 3.75rem;
    }
  }
}

.root__device-type_mobile {
  .container {
    @include ctx-landscape {
      padding: 0 3.75rem;
    }
  }
}

.container-inner {
  @extend .container;

  max-width: 1200px;
}
