@import "src/common/styles/mixins";
@import "src/sections/header/_variables";
@import "src/common/styles/variables";
@import "animation";

$open-animation-timing: 300ms;

.menu {
  outline: transparent;

  &__list {
    color: #032436;
    display: flex;
    font-size: 1rem;
    font-weight: 700;

    @include ctx_mobile {
      font-size: 1.5rem;
    }
  }

  &__button {
    width: 1.5rem;
    height: 1.5rem;
  }

  &__button_icon {
    width: 100%;
    height: 100%;
  }

  &__item {
    position: relative;
    cursor: pointer;
    display: inline-block;
    margin-left: 3.262%;
    overflow: hidden;
    text-decoration: none;

    font-weight: 700;
    font-size: 16px;
    color: #032436;

    &:not(div)::after {
      position: absolute;
      bottom: 0;
      left: 50%;
      z-index: -1;
      width: 100%;
      height: 0.5rem;
      background-color: #00c3ee;
      content: "";
      opacity: 0;
      transform: translate(-50%, 100%);
    }

    &:first-child {
      margin-left: auto;
    }

    &:hover {
      &::after {
        animation: menu-item-underline-appear 300ms linear forwards;
      }
    }
  }

  &__phone {
    margin-left: auto;
  }

  &__phone_link {
    color: $tangaroa;
    text-decoration: none;
    white-space: nowrap;
  }

  .icon_burger_top,
  .icon_burger_middle,
  .icon_burger_bottom {
    animation-duration: 700ms;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
    transform-origin: center;
  }

  .icon_burger_top {
    animation-name: top-back;
  }

  .icon_burger_middle {
    animation-name: scaled-back;
  }

  .icon_burger_bottom {
    animation-name: bottom-back;
  }

  &_mobile {
    display: flex;

    .menu__list {
      position: fixed;
      top: $header-height-mobile;
      left: 0;
      width: 100vw;
      height: 0;
      background-color: #ffffff;
      display: flex;
      align-items: center;
      flex-direction: column;
      opacity: 0;
      overflow: hidden;
      transition:
        opacity $open-animation-timing ease-in-out,
        height $open-animation-timing ease-in-out;
    }

    .menu__item {
      font-size: 1.5rem;
      margin-left: 0;
      opacity: 0;
      transform: translateY(-100%);

      &:first-child {
        @include ctx-landscape {
          margin-top: 3.2154%;
        }

        @include ctx-portrait {
          margin-top: 16%;
        }
      }

      @include ctx-landscape {
        margin-top: 0;
      }

      @include ctx-portrait {
        margin-top: 5.3068%;
      }
    }

    .menu__button {
      margin-left: auto;
    }

    .menu__phone {
      margin: auto auto 1.4375rem auto;
      opacity: 0;
    }
  }

  &_active {
    .menu__list {
      height: calc(100vh - #{$header-height-mobile});
      opacity: 1;
      overflow: auto;
    }

    .menu__item {
      animation: menu-item-appear $open-animation-timing ease forwards;

      @for $i from 1 through 6 {
        &:nth-child(#{$i}) {
          animation-delay: (($i * 70) + 100ms);
        }
      }
    }

    .menu__phone {
      animation: menu-phone-appear $open-animation-timing ($open-animation-timing * 2) ease forwards;
    }

    .icon_burger_top {
      animation-name: top;
    }

    .icon_burger_middle {
      animation-name: scaled;
    }

    .icon_burger_bottom {
      animation-name: bottom;
    }
  }
}
