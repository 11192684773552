.ls {
  &Container {
    display: flex;
    margin-left: 48px;
  }

  &Separator {
    width: 2px;
    height: 20px;
    background: #BEE5F6;
  }

  &Switcher {
    color: #032436;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    margin: 0 8px;
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 8px;
      left: 0;
      padding: 4px 0;
      z-index: -1;
      display: flex;
      bottom: -10px;
      background: #00C3EE;
      transition: all 300ms ease;
    }

    &:hover {
      &:after {
        bottom: 4px;
      }
    }
  }
}

.selected {
  &:after {
    bottom: 4px;
  }
}
