@import '../../common/styles/helpers';
@import '../../common/styles/variables';
@import '../../common/styles/common';

.benefits {
  @extend .container;

  position: relative;
  z-index: 1;
  background: $whisper;
  border-radius: 40px;
  display: flex;
  overflow: hidden;
  padding: 5rem 0 2.8125rem 8.125rem;

  @include ctx_mobile {
    border-radius: 0;
    flex-direction: column;
    margin-bottom: 2.5rem;

    @include ctx-landscape {
      padding: 1.5rem 3.75rem;
    }

    @include ctx-portrait {
      padding: 1.5rem 1rem;
    }
  }

  &Swiper {
    @include ctx_mobile {
      padding: 1rem;
      margin-bottom: 4rem;
    }
  }

  &PatternHolder {
    position: relative;
    margin-bottom: 270px;

    @include ctx_mobile {
      display: none;
    }

    img {
      position: absolute;
      z-index: 0;
      right: -97px;
      top: -124px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    bottom: 0;
    width: 30px;
    height: 100%;
    background: linear-gradient(270deg, rgba(208, 208, 224, 0.4) 0%, rgba(232, 232, 236, 0) 100%);

    @include ctx_mobile {
      display: none;
    }
  }

  &Wrapper {
      min-width: 350px;
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &Title {
        font-weight: 600;
        font-size: 28px;

        &Image {
          margin-bottom: 40px;
        }

        &Text {
          color: $tangaroa;
        }
      }

      &Slider {
        max-width: 100%;
        overflow-x: hidden;

        &Slide {
          float: none;
          display: flex;
          flex-direction: column;
          width: 344px !important;
          height: 344px !important;
          background: #ffffff;
          box-shadow: 0 4px 23px rgba(3, 36, 54, 0.1), 0 4px 3px rgba(3, 36, 54, 0.05);
          border-radius: 40px;
          padding: 28px 22px;

          @include ctx_mobile {
            @include ctx-landscape {
              width: 47% !important;
            }

            @include ctx-portrait {
              width: 100% !important;
            }
          }

          &Icon {
            margin-bottom: 8px;
          }

          &Title {
            font-weight: 600;
            font-size: 28px;
            color: $tangaroa;
            margin-bottom: 16px;
          }

          &Description {
            font-weight: 500;
            font-size: 16px;
            color: $trout;
          }
        }

        &Control {
          width: 64px;
          height: 64px;
          border-radius: 32px;
          border: 1px solid #032436;
          background: transparent;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: all 300ms ease-in-out;

          svg {
            path {
              transition: all 300ms ease-in-out;
            }
          }

          &:hover {
            background: #032436;

            svg {
              path {
                fill: #ffffff;
              }
            }
          }

          &:first-child {
            margin-right: 32px;
          }
        }

        &Controls {
          position: absolute;
          display: flex;

          @include ctx_desktop {
            bottom: 4rem;
          }

          @include ctx_mobile {
            bottom: 1rem;
            align-self: center;
          }
        }
      }
    }
}
