@import "~src/common/styles/mixins";
@import "./variables";

.header {
  position: sticky;
  top: 0;
  z-index: 99;
  height: $header-height-desktop;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 2.8125rem 0 1.1875rem;
  transition: box-shadow 200ms ease-in-out;

  @include ctx_mobile {
    height: $header-height-mobile;
    padding: 0;
  }

  &__active {
    box-shadow: 0 0.25rem 1.4375rem rgba(3, 36, 54, 0.1);
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__logo {
    outline: transparent;
  }

  &__menu {
    width: 100%;
  }

  &__menu-item {
    outline: transparent;
  }
}
